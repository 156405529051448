
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BannerContent extends Vue {
  @Prop({
    type: Object,
    required: false
  })
  private banner: any
}
