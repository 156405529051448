
import { Component, Prop, Vue } from 'vue-property-decorator'
import Follow from '~/components/shared/Follow.vue'

@Component({
  components: {
    Follow
  }
})
export default class CompanySquareCard extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private company!: any
}
