
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Cookies from 'universal-cookie'
import authSetting from '~/config/authSetting'
import MixinMemberId from '~/components/mixins/MixinMemberId'
import { errorHandler } from '~/lib/errorHandling'

@Component({
  mixins: [MixinMemberId]
})
export default class Follow extends Vue {
  @Prop({
    type: String,
    required: true
  })
  private followText!: string

  @Prop({
    type: String,
    required: true
  })
  private type!: string

  @Prop({
    type: Object,
    required: true
  })
  private item!: any

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  private card!: boolean

  @Prop({
    type: String,
    required: false
  })
  private used!: string

  private memberId!: number
  private localFollowing: boolean = false

  data() {
    return {
      localFollowing: false
    }
  }

  @Watch('$store.state.member.memberFollowing', {
    immediate: true,
    deep: true
  })
  onMemberFollowingChange() {
    this.updateLocalFollowing()
  }

  private mounted() {
    if (this.$store.state.member.memberFollowing) {
      this.updateLocalFollowing()
    } else {
      this.$watch(
        () => this.$store.state.member.memberFollowing,
        () => {
          this.updateLocalFollowing()
        },
        { immediate: true, deep: true }
      )
    }
  }

  private updateLocalFollowing() {
    const memberFollowing = this.$store.state.member.memberFollowing
    if (!memberFollowing || !memberFollowing.following) {
      this.localFollowing = false
      return
    }

    const key = this.type === 'company' ? 'companies' : 'categories'
    const targetArray = memberFollowing.following[key]
    if (!Array.isArray(targetArray)) {
      this.localFollowing = false
      return
    }

    this.localFollowing = targetArray.some(
      (target: { id: number }) => target.id === this.item.id
    )
  }

  private get isFollow(): boolean {
    const memberFollowing = this.$store.state.member.memberFollowing
    if (!memberFollowing || !memberFollowing.following) {
      return false
    }

    const key = this.type === 'company' ? 'companies' : 'categories'
    const targetArray = memberFollowing.following[key]
    if (!Array.isArray(targetArray)) {
      return false
    }

    return targetArray.some(
      (target: { id: number }) => target.id === this.item.id
    )
  }

  private toggleFollow(event: Event): void {
    event.stopPropagation()
    const previewPath = /^\/preview\//.test(this.$route.path)
    if (!previewPath && !this.memberId) {
      this.$store.dispatch('modal/changeNonMemberModalState', true)
    } else if (!previewPath) {
      this.isFollow ? this.delete() : this.submit()
    }
  }

  private async delete(): Promise<void> {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      const token = await this.$axios.$get(csrfUrl, {
        auth: authSetting,
        withCredentials: true
      })
      await this.deleteAction(token.csrf_token)
    } catch (err) {
      errorHandler(err, this.$nuxt.context)
    }
  }

  private async deleteAction(csrf: string): Promise<void> {
    const cookies = new Cookies()
    const url =
      this.type === 'company'
        ? process.env.API_URL + `/members/${this.memberId}/follows`
        : process.env.API_URL + `/members/${this.memberId}/category_follows`
    const deleteData = {
      [`${this.type}_id`]: this.item.id
    }
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      data: deleteData,
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios.$delete(url, config)
      this.localFollowing = false
      this.$store.dispatch('member/updateFollowing', {
        item: this.item,
        type: this.type,
        flag: 'remove'
      })
    } catch (err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 404)
      ) {
        if (!cookies.get('unauthorized_error')) {
          cookies.set('unauthorized_error', true)
          sessionStorage.removeItem('member')
          window.location.href = '/'
        }
      }
    }
  }

  private async submit(): Promise<void> {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      const token = await this.$axios.$get(csrfUrl, {
        auth: authSetting,
        withCredentials: true
      })
      await this.submitAction(token.csrf_token)
    } catch (err) {
      errorHandler(err, this.$nuxt.context)
    }
  }

  private async submitAction(csrf: string): Promise<void> {
    const cookies = new Cookies()
    const url =
      this.type === 'company'
        ? process.env.API_URL + `/members/${this.memberId}/follows`
        : process.env.API_URL + `/members/${this.memberId}/category_follows`
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    const postData = {
      [`${this.type}_id`]: this.item.id
    }
    try {
      await this.$axios.$post(url, postData, config)
      this.localFollowing = true
      this.$store.dispatch('member/updateFollowing', {
        item: this.item,
        type: this.type,
        flag: 'add'
      })
    } catch (err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 404)
      ) {
        if (!cookies.get('unauthorized_error')) {
          cookies.set('unauthorized_error', true)
          sessionStorage.removeItem('member')
          window.location.href = '/'
        }
      }
    }
  }
}
