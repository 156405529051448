
import { Component, Prop, Vue } from 'vue-property-decorator'
import ArticleCard from '~/components/shared/card/ArticleCard.vue'
import BannerContent from '~/components/shared/BannerContent.vue'
import CompanySquareCard from '~/components/shared/card/CompanySquareCard.vue'

@Component({
  components: {
    ArticleCard,
    BannerContent,
    CompanySquareCard
  }
})
export default class CarouselContents extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  private items!: any[]

  @Prop({
    type: String,
    required: true
  })
  private type!: string

  @Prop({
    type: String,
    required: false
  })
  private used!: string

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  private display!: string

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private recommend!: boolean

  private isTopCarousel = this.used === 'top-carousel'

  // 同一ページにcomponentを複数配置するとclassが重複してしまい、next/prevの操作時に意図しないslideが起きる為uniqueIdを生成
  private uniqueId = `swiper-${Date.now()}-${Math.random()
    .toString(36)
    .substr(2, 9)}`

  private get carouselSetting() {
    // 基本設定
    const settings: any = {
      slidesPerView: this.isTopCarousel ? 1.3 : 1.2,
      spaceBetween: 24,
      loop: this.isTopCarousel,
      centeredSlides: this.isTopCarousel,
      speed: 1000,
      touchRatio: 1,
      touchReleaseOnEdges: false,
      watchSlidesVisibility: true,
      observeParents: true,
      observer: true,
      slideToClickedSlide: false,
      navigation: {
        prevEl: `.${this.uniqueId}-prev`,
        nextEl: `.${this.uniqueId}-next`
      },
      breakpoints: {
        767: {
          slidesPerView: 'auto',
          spaceBetween: this.isTopCarousel ? 64 : 32
        }
      }
    }
    // top-carouselの場合にpaginationを追加
    if (this.isTopCarousel) settings.pagination = { el: '.swiper-pagination' }
    return settings
  }

  private swiperInstance = null

  private mounted() {
    this.$nextTick(() => {
      const swiperElements = this.$el.querySelectorAll('.swiper-container')
      const swiperContent = (this as any).$refs.swiperContent.$swiper
      this.swiperInstance = swiperContent
      if (this.$refs.swiper) swiperContent.update()
      swiperElements.forEach((swiperEl) => {
        swiperEl.removeEventListener('touchstart', this.handleTouchEvent)
        swiperEl.removeEventListener('touchmove', this.handleTouchEvent)
        swiperEl.removeEventListener('touchend', this.handleTouchEvent)
        swiperEl.addEventListener('touchstart', this.handleTouchEvent)
        swiperEl.addEventListener('touchmove', this.handleTouchEvent)
        swiperEl.addEventListener('touchend', this.handleTouchEvent)
      })
    })
  }

  private handleTouchEvent(event: TouchEvent) {
    event.stopPropagation()
  }

  handleSlideClick() {
    // スライドのトランジションを無効化
    if (this.swiperInstance) this.swiperInstance.setTransition(0)
  }

  private get displayButton() {
    // display:fixed;(コンテンツ幅が固定)の場合は、lengthが4を超える場合のみボタンを表示
    return this.display !== 'fixed' || this.items.length > 4
  }
}
